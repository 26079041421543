import React from "react";
import { Link } from "react-router-dom";


export default function GetInTuch() {
  return (
    <div className="container relative md:mt-24 mt-16">
      <div className="grid grid-cols-1 text-center">
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Have Question ? Get in touch!</h3>

        <p className="text-slate-400 max-w-xl mx-auto">Our friendly support team is here to help. Contact us with any questions or concerns.</p>

        <div className="mt-6">
          <Link to="/contact" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-400 border-blue-500 hover:border-blue-400 text-white rounded-md me-2 mt-2">
            Contact us
          </Link>
        </div>
      </div>
    </div>
  )
}
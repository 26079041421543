import { gql } from "@apollo/client";


export const LOGIN_USER_MUTATION = gql`
    mutation login($email: String, $password: String) {
        login(email: $email, password: $password) {
                id,
                firstName,
                lastName,
                email,
                profilePicture,
                profilePictureBg,
        }
    }
`

export const RESET_EMAIL_MUTATION = gql`
    mutation resetEmail($email: String) {
        resetEmail(email: $email)
    }
`

export const UPDATE_PWD_WITH_HASH_MUTATION = gql`
    mutation updatePwdWithHash($email: String, $hash: String, $password: String) {
        updatePwdWithHash(email: $email, hash: $hash, password: $password)
    }
`

export const LOGOUT_MUTATION = gql`
    mutation logout {
        logout
    }
`

export const DELETE_ACCOUNT = gql`
    mutation deleteAccount {
        deleteAccount
    }
`

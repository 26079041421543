import { gql } from "@apollo/client";


export const CREATE_USER_MUTATION = gql`
    mutation register($nickName: String, $email: String, $password: String) {
        register(nickName: $nickName, email: $email, password: $password) {
                email
        }
    }
`

export const SET_USER_INFORMATIONS = gql`
    mutation updatedUser($firstName: String, $lastName: String, $nickName: String, $email: String, $gender: String, $profilePicture: String, $birthday: Int, $coverPicture: String, $coverPicturePosition: Float, $location: String, $bio: String, $profilePictureBg: String, $phone: String) {
        updatedUser(firstName: $firstName, lastName: $lastName, nickName: $nickName, email: $email, gender: $gender, profilePicture: $profilePicture, birthday: $birthday, coverPicture: $coverPicture, coverPicturePosition: $coverPicturePosition, location: $location, bio: $bio, profilePictureBg: $profilePictureBg, phone: $phone) {
            id,
            firstName,
            lastName,
            nickName,
            email,
            gender,
            profilePicture,
            profilePictureBg,
            coverPicture,
            coverPicturePosition,
            birthday,
            location,
            bio,
            isVerified,
            isEmailVerified,
            phone,
            isPhoneVerified,
            isWaitingPhoneVerification
        }
    }
`

export const SET_USER_PASSWORD = gql`
    mutation updatedUserPwd($oldPwd: String, $newPwd: String) {
        updatedUserPwd(oldPwd: $oldPwd, newPwd: $newPwd) 
    }
`

export const SET_IS_ONLINE_MUTAION = gql`
    mutation setUserOnline {
        setUserOnline
    }
`

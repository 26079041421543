import { useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo_light from '../../assets/images/logo-white.png';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import { VERIFY_EMAIL_MUTATION } from '../../graphql/mutations/accountVerification';
import { FaCheck } from "react-icons/fa";


export default function AuthVerifyEmail() {

    const [timer, setTimer] = useState()

    const { email: encodedEmail, token: encodedToken } = useParams()

    const email = useMemo(() => atob(encodedEmail), [encodedEmail])
    const token = useMemo(() => atob(encodedToken), [encodedToken])

    const [verifyEmail, emailVerificationQuery] = useMutation(VERIFY_EMAIL_MUTATION)

    useEffect(() => {
        if (email?.length && token?.length) {
            verifyEmail({
                variables: {
                    email,
                    token
                }
            }).then(() => {
                setTimer(setTimeout(() => {
                    window.location.href = process.env.REACT_APP_ENDPOINT
                }, 2000))
            })
        }
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Email Verificaion</h3>
                    </div>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl py-6 px-12" style={{ maxWidth: 480, margin: "0 auto" }}>
                                    <div>
                                        <img src={logo_light} style={{ width: 120, margin: "0 auto", marginBottom: 34 }} alt="" />
                                    </div>

                                    <h3 className="mb-3 text-2xl leading-normal font-medium text-blue-500">Email verification</h3>

                                    {
                                        //loading
                                        emailVerificationQuery?.loading &&
                                        <p className='text-slate-300 mb-10'>You email is being verified, please wait.</p>
                                    }

                                    {
                                        //email verified
                                        emailVerificationQuery?.data?.emailVerification?.isEmailVerified &&
                                        <>
                                            <p className='text-slate-300 mb-10'>You email is now verified. You will be redirected to home page in some seconds.</p>
                                            <FaCheck style={{
                                                width: 60,
                                                height: 60,
                                                color: "#309668",
                                                marginTop: 16,
                                                margin: "0 auto"
                                            }} />
                                        </>
                                    }
                                    {
                                        //error
                                        emailVerificationQuery?.error &&
                                        <>
                                            <p className='text-slate-300'>An error occurred while verifying your email :</p>
                                            <p className='my-2 error_message text-left'>
                                                {emailVerificationQuery?.error?.graphQLErrors[0]['message']}
                                            </p>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';


export default function PageAboutus() {

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">About us</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3 mb-2">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">About us</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8">
                        <h3 className="mb-2 md:text-xl text-xl md:leading-normal leading-normal font-semibold">The company</h3>
                        <p className="text-gray-400">A startup located in Paris, France.</p>
                    </div>
                    <div className="grid grid-cols-1 pb-8">
                        <h3 className="mb-2 md:text-xl text-xl md:leading-normal leading-normal font-semibold">The Concept</h3>

                        <p className="text-gray-400">
                            TheWide is a new social network tailored specifically for developers, programmers, and coders, offering a unique concept.
                        </p>
                        <p className="text-gray-400 mt-2">
                            On TheWide, users can join a community of developers or IT specialists based on their preferred technologies, in order to benefit from a highly targeted and collaborative community. In addition to classic features such as image and video sharing, chat, reactions, and comments, TheWide offers special and unique features, including:
                        </p>
                        <ul className="list-none footer-list mt-5 text-gray-400">
                            <li className="flex ml-2 mb-2">
                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                Direct Source Code Sharing in the Feed: Users can share source code by tapping the "+" button located at the bottom of the screen.
                            </li>
                            <li className="flex ml-2 mb-2">
                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                Code Discussions:  To facilitate code reviews and collaboration among developers, it is possible to engage in discussions using code.
                            </li>
                            <li className="flex ml-2 mb-2">
                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                Reward system: TheWide has a reward system allowing users to monetize their activity on the platform.
                            </li>
                            <li className="flex ml-2 mb-2">
                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                Idea Center: Users can propose features they deem useful for the application via an idea center accessible quickly from the menu bar.
                            </li>
                            <li className="flex ml-2">
                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                Bug Reporting System: Users can quickly report encountered issues, allowing the development team to intervene promptly through the idea center.
                            </li>
                        </ul>

                    </div>
                </div>

            </section>

            <Footer />
        </>
    )
}

import { gql } from "@apollo/client";

export const VERIFY_EMAIL_MUTATION = gql`
    mutation emailVerification($email: String, $token: String) {
        emailVerification(email: $email, token: $token) {
            id,
            isVerified,
            isEmailVerified,
            isPhoneVerified
        }
    }
`

export const VERIFY_PHONE_CODE_MUTATION = gql`
    mutation phoneCodeVerification($code: String, $phone: String) {
        phoneCodeVerification(code: $code, phone: $phone) {
            id,
            isVerified,
            isPhoneVerified,
            isWaitingPhoneVerification
        }
    }
`

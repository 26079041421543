import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/css/tailwind.css';
import logo_light from '../../assets/images/logo-white.png';
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu, setManu] = useState('');
    let location = useLocation();

    useEffect(() => {
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])


    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
            'bg-white dark:bg-slate-900' : ''}`}>
            <div className="container relative">
                {
                    <Link className="logo" to="/index">
                        <img src={logo_light} className="hidden dark:inline-block" alt="" />
                    </Link>
                }

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className={`navigation-menu ${navClass} ${navJustify}`}>

                        <li className={manu === "index" || "" ? "active" : ""}><Link to="/index" className="sub-menu-item">Home</Link></li>

                        <li className={manu === "helpcenter" || "" ? "active" : ""}><Link to="/helpcenter" className="sub-menu-item">Help Center</Link></li>

                        <li className={manu === "contact" || "" ? "active" : ""}><Link to="/contact" className="sub-menu-item">Contact</Link></li>

                        <li className={manu === "faq" ? "active" : ""}><Link to="/faq" className="sub-menu-item">FAQ</Link></li>
                        
                        <li className={manu === "aboutus" ? "active" : ""} style={{ marginRight: 60 }}><Link to="/aboutus" className="sub-menu-item">About</Link></li>

                        <li className={"login"}>
                            <div className='login'>
                                <Link
                                    to="/login"
                                    style={{ width: 100, margin: "0 auto" }}
                                    className="py-2 px-5 mt-8 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center hover:bg-white border-gray-400 hover:text-black text-white rounded-2xl">
                                    Log in
                                </Link>
                            </div>
                        </li>

                        <li className={"signup"}>
                            <div className='register'>
                                <Link
                                    to="/signup"
                                    style={{ width: 110, margin: "0 auto" }}
                                    className="py-2 px-5 mt-8 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-400 border-indigo-600/10 text-white rounded-2xl">
                                    Sign Up
                                </Link>
                            </div>
                        </li>

                    </ul>
                </div>
            </div >
        </nav >
    )
}

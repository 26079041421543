import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import AuthCreatePassword from './pages/auth/authCreatePassword';
import AuthLogin from './pages/auth/authLogin';
import AuthRePassword from './pages/auth/authRePassword';
import AuthSignup from './pages/auth/authSignup';
import PageAboutus from './pages/company/pageAboutus';
import Contact from './pages/contact/contact';
import Helpcenter from './pages/helpcenter/helpcenter';
import HelpcenterFaqs from './pages/helpcenter/helpcenterFaqs';
import Index from './pages/index';
import { ApolloClient, ApolloProvider, InMemoryCache, from } from "@apollo/client";
import { errorLink, httpLink } from './utils/ApolloClient';
import AuthVerifyEmail from './pages/auth/authVerifyEmail';

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    // link: persistedQueriesLink.concat(httpLink)
    link: from([errorLink, httpLink]),
  });

export default function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Index />} />
                    <Route exact path="/index" element={<Index />} />
                    <Route exact path="/helpcenter" element={<Helpcenter />} />
                    <Route exact path="/faq" element={<HelpcenterFaqs />} />
                    <Route exact path="/contact" element={<Contact />} />
                    <Route exact path="/aboutus" element={<PageAboutus />} />

                    <Route exact path="/login" element={<AuthLogin />} />
                    <Route exact path="/signup" element={<AuthSignup />} />
                    <Route exact path="/password-reset" element={<AuthRePassword />} />
                    <Route exact path="/verify-email/:email/:token" element={<AuthVerifyEmail />} />
                    <Route exact path="/create-password/:email/:hash" element={<AuthCreatePassword />} />
                </Routes>
            </BrowserRouter>
        </ApolloProvider>
    )
}

import React, { useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import logo_light from '../../assets/images/logo-white.png';
import { isEmailValid } from '../../utils/Validator';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PWD_WITH_HASH_MUTATION } from '../../graphql/mutations/auth';
import { CHECK_RESET_PWD_HASH_QUERY } from '../../graphql/queries/auth';

export default function AuthCreatePassword() {

    const { email: encodedEmail, hash: encodedHash } = useParams()

    const email = useMemo(() => atob(encodedEmail), [encodedEmail])
    const hash = useMemo(() => atob(encodedHash), [encodedHash])

    const [pwd, setPwd] = useState('')
    const [pwdConfirmation, setPwdConfirmation] = useState('')
    const [isExpired, setIsExpired] = useState(false)
    const [okText, setOkText] = useState('')
    const [errorText, setErrorText] = useState('')

    const pwdError = pwd?.length >= 1 && pwd?.length < 6
    const pwdConfirmationError = pwd?.length >= 6 && pwdConfirmation?.length >= 1 && pwdConfirmation !== pwd

    const checkHashQuery = useQuery(CHECK_RESET_PWD_HASH_QUERY, { variables: { email, hash }, fetchPolicy: "network-only" });
    const [updatePassword, updatePasswordQuery] = useMutation(UPDATE_PWD_WITH_HASH_MUTATION, { fetchPolicy: "network-only" });


    const handleSaveClick = async (e) => {
        e.preventDefault();
        updatePassword({
            variables: {
                email: email,
                password: pwd,
                hash: hash
            }
        })
    }

    const validForm = () => {
        return pwd?.length >= 6 && pwdConfirmation === pwd
    }

    const handlePwdChange = (event) => {
        setPwd(event.target.value)
    }

    const handlePwdConfirmationChange = (event) => {
        setPwdConfirmation(event.target.value)
    }

    useEffect(() => {
        if (checkHashQuery?.error) {
            setIsExpired(true)
            localStorage.setItem("email", email)
        }
    }, [checkHashQuery?.error, email])

    //bad params
    useEffect(() => {
        if (!hash || hash.length !== 16 || !isEmailValid(email ?? '')) {
            setIsExpired(true)
        }
    }, [email, hash])

    //ok
    useEffect(() => {
        if (updatePasswordQuery?.data?.updatePwdWithHash) {
            setOkText("You can now log in with the new password.")
            setErrorText("")
        }
    }, [updatePasswordQuery?.data?.updatePwdWithHash])

    // errors
    useEffect(() => {
        if (updatePasswordQuery.error) {
            setErrorText(updatePasswordQuery.error.message)
            setOkText("")
        }
    }, [updatePasswordQuery.error])

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Create new password</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Create password</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl py-6 px-12" style={{ maxWidth: 480, margin: "0 auto" }}>
                                    <div>
                                        <img src={logo_light} style={{ width: 120, margin: "0 auto", marginBottom: 34 }} alt="" />
                                    </div>

                                    <h3 className="mb-3 text-2xl leading-normal font-medium text-blue-500">Set a Strong Password</h3>

                                    <p className='text-slate-300 mb-10'>Create a strong password that's easy for you to remember but difficult for others to guess.</p>

                                    <form className="text-start">
                                        <div className="grid grid-cols-1">

                                            <div>
                                                <label className="font-semibold" htmlFor="password">Password:</label>
                                                <div>
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        value={pwd}
                                                        onChange={handlePwdChange}
                                                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-blue-500 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                        placeholder="Password" />
                                                    <div className='error'>
                                                        <div className='error_message' style={{ textAlign: "left" }}>
                                                            {
                                                                pwdError &&
                                                                <span>At least 6 characters.</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="font-semibold" htmlFor="passwordConfirmation">Password confirmation:</label>
                                                <input
                                                    id="passwordConfirmation"
                                                    name="passwordConfirmation"
                                                    type="password"
                                                    value={pwdConfirmation}
                                                    onChange={handlePwdConfirmationChange}
                                                    className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-blue-500 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                    placeholder="Confirmation" />
                                                <div className='error'>
                                                    <div className='error_message' style={{ textAlign: "left" }}>
                                                        {
                                                            pwdConfirmationError &&
                                                            <span>Bad password confirmation.</span>
                                                        }
                                                        {
                                                            errorText?.length > 0 ? errorText : null
                                                        }
                                                        {
                                                            (isExpired > 0) && <div>
                                                                <span>This link has expired! </span>
                                                                <Link to="/password-reset" className="text-blue-500 font-bold inline-block">Request new link</Link>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='error' style={{ display: okText?.length ? 'block' : 'none' }}>
                                                        <span className='success_message'>
                                                            {okText + " "}
                                                            <Link to="/login" className="text-blue-500 font-bold inline-block">Log in</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <input type="submit"
                                                disabled={!validForm()}
                                                onClick={handleSaveClick}
                                                className="hover my-4 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 text-white rounded-2xl w-full"
                                                value="Save" />

                                            <div className="text-center">
                                                <span className="text-slate-400 me-2">Remember your password ?</span> <Link to="/login" className="text-black dark:text-white font-bold inline-block">Log in</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import logo_light from '../../assets/images/logo-white.png';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import { RESET_EMAIL_MUTATION } from '../../graphql/mutations/auth';
import { isEmailValid } from '../../utils/Validator';

export default function AuthRePassword() {

    // const [badEmailText, setBadEmailText] = useState()
    const [resetError, setResetError] = useState('')
    const [okText, setOkText] = useState('')
    const [email, setEmail] = useState(localStorage.getItem("email") ?? '')

    const [reset, { error }] = useMutation(RESET_EMAIL_MUTATION, { fetchPolicy: "network-only" });

    const handleInputChange = (event) => {
        setEmail(event.target.value)
    }

    const handleEmailBlur = useCallback(() => {
        const _email = email?.trim()
        setEmail(_email)

        if (Number(_email?.length) > 0 && !isEmailValid(_email ?? '')) {
            setResetError('Bad email')
            setOkText("")
        }
        else {
            setResetError(undefined)
        }
    }, [email])

    const handleSendClick = useCallback((e) => {
        e.preventDefault();
        reset({
            variables: {
                email: email
            }
        }).then((res) => {
            if (res?.data?.resetEmail) {

                localStorage.setItem("email", email)
                setEmail('')
                setResetError('')
                setOkText("We have sent you an email to reset your password.")
            }
        })
    }, [email, reset])

    useEffect(() => {
        //register errors
        if (error) {
            setResetError(error.message)
            setOkText("")
        }
    }, [error])

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Reset Your Password</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Reset Password</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl py-6 px-12" style={{ maxWidth: 480, margin: "0 auto" }}>
                                    <div>
                                        <img src={logo_light} style={{ width: 120, margin: "0 auto", marginBottom: 34 }} alt="" />
                                    </div>

                                    <h3 className="mb-3 text-2xl leading-normal font-medium text-blue-500">Recover Your Account</h3>

                                    <p className='text-slate-300 mb-10'>Please enter your email address. You will receive a link to create a new password via email.</p>

                                    <form className="text-start">
                                        <div className="grid grid-cols-1">
                                            <div>
                                                <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                                <input
                                                    id="LoginEmail"
                                                    name="email"
                                                    value={email}
                                                    onBlur={handleEmailBlur}
                                                    onChange={handleInputChange}
                                                    type="email"
                                                    className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                    placeholder="name@example.com" />
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            resetError?.length > 0 ? resetError : null
                                                        }
                                                    </span>
                                                    <span className='success_message'>
                                                        {okText}
                                                    </span>
                                                </div>
                                            </div>

                                            <input
                                                type="submit"
                                                onClick={handleSendClick}
                                                disabled={!isEmailValid(email)}
                                                className="hover my-4 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 text-white rounded-2xl w-full" value="Send" />

                                            <div className="text-center">
                                                <span className="text-slate-400 me-2">Remember your password ?</span> <Link to="/login" className="text-black dark:text-white font-bold inline-block">Log in</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

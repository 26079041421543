import React from 'react';
import CountUp from 'react-countup';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import VisibilitySensor from 'react-visibility-sensor';
import home from '../../assets/images/saas/home.png';
import followCoder from '../../assets/images/screenshot/follow-coders.jpg';
import shareContentImg from '../../assets/images/screenshot/share-content.png';
import sparksDashboard from '../../assets/images/screenshot/sparks dashboard video.webp';
import bg from '../../assets/images/stock.jpg';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import CookieModal from '../../component/cookieModal';
import UserFeedBack from '../../component/userFeedBack';

export default function Index() {

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative md:h-screen md:py-0 bg-[url('../../assets/images/bg2.png')] py-16 items-center bg-black overflow-hidden bg-center bg-no-repeat bg-cover" style={{ height: "calc(100vh - 66px)" }}>
                <div className="container relative">
                    <div className="grid grid-cols-1 md:mt-36 mt-10 text-center">
                        <h4 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                            The Social Network built <br />for
                            <TypeAnimation
                                cursor={true}
                                sequence={[
                                    ' Tech Enthusiasts',
                                    2000,
                                    ' IT Professionals',
                                    2000,
                                    ' Coders',
                                    2000,
                                    ' Data Scientists',
                                    2000,
                                    ' Cybersecurity Experts',
                                    2000,
                                    ' Cloud Architects',
                                    2000,
                                ]}
                                wrapper="span"
                                speed={50}
                                className="typewrite text-blue-600 bg-clip-text"
                                repeat={Infinity}
                            />
                        </h4>
                        <p className="text-white/70 text-xl max-w-xl mx-auto">Connect, Grow, and Earn with TheWide!</p>

                        <Link to="/login" style={{ width: 210, margin: "0 auto" }} className="py-2 px-2 md:mt-8 md:mb-0 mt-16 mb-8 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-400 text-white hover:text-white rounded-2xl">
                            Get Started for Free
                        </Link>

                        <div className="overflow-hidden mt-8">
                            <img src={home} alt="" />
                        </div>

                    </div>
                </div>
            </section>


            <section className="relative md:py-18 py-16 overflow-hidden">

                <div className="container relative">
                    <div className="grid grid-cols-1 text-center pb-8 pt-6 bg-secondary rounded-3xl" style={{ maxWidth: 500, margin: "0 auto", paddingLeft: 20, paddingRight: 20 }}>
                        <h3 className="mb-5 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                            Free to Join, Free to Use
                        </h3>
                        <p className="text-slate-400 max-w-xl mx-auto">
                            Join TheWide for free and start connecting with developers worldwide. No credit card required.
                        </p>
                        <Link to="/login" style={{ width: 210, margin: "0 auto" }} className="py-2 px-2 mt-8 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center 5 bg-blue-500 hover:bg-blue-400 text-white hover:text-white rounded-2xl">
                            Get Started for Free
                        </Link>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative">
                            <img src={followCoder} alt="" style={{ margin: "0 auto", borderRadius: 12, maxWidth: 520, maxHeight: 360, width: "100%", objectFit: "cover", objectPosition: "top" }} />
                            <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-2/4 translate-y-2/4 start-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
                        </div>

                        <div className="lg:ms-8">
                            <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">Skill-Based Connections</h4>
                            <p className="text-slate-400">Connect with like-minded professionals based on their technical skills, fostering a personalized learning and collaboration experience.</p>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="relative order-1 md:order-2">
                            <img src={shareContentImg} alt="" style={{ width: "100%" }} />
                            <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-2/4 translate-y-2/4 end-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
                        </div>

                        <div className="lg:me-8 order-2 md:order-1">
                            <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">
                                Express Yourself: Code, Text, Images, Videos & Shorts
                            </h4>
                            <p className="text-slate-400">
                                Share everything from funny dev memes and the latest IT news to insightful tutorials and quick coding snippets in Shorts.
                            </p>
                            <p className="text-slate-400">
                                Use the integrated IDE to share code snippets.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mt-16 gap-[30px] text-center" style={{ maxWidth: 480, margin: "0 auto" }}>
                    <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <Icon.Hexagon className="size-60 fill-blue-500 mx-auto rotate-[30deg]"></Icon.Hexagon>
                            <div className="absolute text-white top-2/4 -translate-y-2/4 start-0 end-0 mx-auto rounded-xl transition duration-500 ease-in-out text-xl text-center">
                                <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                                    {({ isVisible }) => (
                                        <div style={{ height: 40 }}>
                                            {
                                                isVisible ?
                                                    <CountUp className="counter-value text-6xl font-bold" start={0} end={426}></CountUp> :
                                                    <span className="counter-value text-3xl font-bold">1</span>
                                            }
                                        </div>
                                    )}
                                </VisibilitySensor>
                                <br />
                                <span className="text-slate-300 font-bold">Sparks </span>
                                <span className="text-slate-300">earned</span>
                            </div>
                        </div>

                        <div className="mt-6 px-3">
                            <Link href="#" className="text-3xl font-medium hover:text-indigo-600 duration-500 ease-in-out">Rewards for Every Contribution</Link>
                            <p className="text-slate-400 transition duration-500 ease-in-out mt-3 px-2">Earn sparks for every post, comment, reaction, and Short you create. <b className='text-slate-200'>Sparks are your in-app currency</b>, earned by actively participating in our community.</p>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <img src={sparksDashboard} alt="" style={{ borderRadius: 12 }} />

                        <div className="lg:ms-8">
                            <h4 className="mb-4 text-2xl leading-normal font-medium">
                                $ Turn Your Sparks into Cash $
                            </h4>
                            <p className="text-slate-400">
                                Once you've accumulated enough Sparks, you can redeem them for real-world rewards. Simply transfer your Sparks to PayPal and receive a direct payment.
                            </p>
                        </div>
                    </div>
                </div>

                <section className="mt-24 my-6 py-20 w-full table relative bg-fixed bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="absolute inset-0 bg-gradient-to-tl to-indigo-600/75 from-yellow-600/75"></div>
                    <div className="container relative">
                        <div className="grid grid-cols-1 text-center">
                            <h3 className="mb-4 md:text-4xl text-3xl text-white font-semibold">Spark value is always on the move.</h3>

                            <p className="text-white/70 max-w-xl text-lg mx-auto">The value of your Sparks is constantly evolving. Track the real-time fluctuations of Spark value on the Sparks Dashboard and time your redemptions strategically.</p>

                            <Link to="/login" style={{ width: 210, margin: "0 auto" }} className="py-2 px-2 mt-8 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-400 text-white hover:text-white rounded-2xl">
                                Get Started for Free
                            </Link>
                        </div>
                    </div>
                </section>

            </section >

            <UserFeedBack />

            <Footer />
            <CookieModal />
        </>
    )
}

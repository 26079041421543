import React from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import img1 from '../assets/images/mobile-screenshots/1.png';
import img2 from '../assets/images/mobile-screenshots/2.png';
import img3 from '../assets/images/mobile-screenshots/3.png';
import img4 from '../assets/images/mobile-screenshots/4.png';
import img5 from '../assets/images/mobile-screenshots/5.png';
import img6 from '../assets/images/mobile-screenshots/6.png';
import img7 from '../assets/images/mobile-screenshots/7.png';
import img8 from '../assets/images/mobile-screenshots/8.png';
import img9 from '../assets/images/mobile-screenshots/9.png';
import img10 from '../assets/images/mobile-screenshots/10.png';
import img11 from '../assets/images/mobile-screenshots/11.png';
import app from '../assets/images/app/app.png';
import playstore from '../assets/images/app/playstore.png';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]

export default function UserFeedBack() {
    let settings = {
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        nav: false,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 600,
        gutter: 12,
        edgePadding: 40,
        responsive: {
            992: {
                items: 3,
                edgePadding: 10,
            },
            600: {
                items: 3,
                edgePadding: 30,
            },
            400: {
                items: 1,
                edgePadding: 100,
            },
        },
    }
    return (
        <div className="container relative mb-20" id="review">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Connect, Grow, and Earn, right from your phone.</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Download the TheWide app today and experience the future of developer collaboration.</p>
            </div>

            <div className="text-center">
                <Link to="https://apps.apple.com/fr/app/thewide/id6463420058" target='_blank'><img src={app} className="inline-block m-1" alt="" style={{ width: 150 }} /></Link>
                <Link to="https://play.google.com/store/apps/details?id=app.thewide.com" target='_blank'><img src={playstore} className="inline-block m-1" alt="" style={{ width: 150 }} /></Link>
            </div>

            <div className="grid grid-cols-1 mt-8 mb-10">
                <div className="tiny-three-item">

                    <TinySlider settings={settings}>
                        {
                            images.map((item, index) => (
                                <div key={index}>
                                    <img src={item} alt="" style={{ width: 320, margin: "0 auto" }} />
                                </div>
                            ))
                        }
                    </TinySlider>

                </div>
            </div>
        </div>
    )
}

import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import logo_light from '../../assets/images/logo-white.png';



export default function Footer() {
    return (
        <footer className="footer bg-secondary relative text-gray-200 dark:text-gray-200">
            <div className="container relative">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="py-[50px] px-0">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">

                                <div className="lg:col-span-6 md:col-span-6">
                                    <Link to="/#" className="text-[22px] focus:outline-none">
                                        <img src={logo_light} alt="" style={{ width: 130 }} />
                                    </Link>
                                    <p className="mt-6 text-gray-300">
                                        TheWide is a Paris-based startup that empowers developers to connect, learn, and grow.
                                        <br />
                                        By fostering a vibrant community, TheWide aims to revolutionize the way developers collaborate and innovate.
                                    </p>
                                    <p className="mb-0 mt-2 text-gray-400" style={{ fontSize: 14 }}>Email: contact@thewide.com</p>
                                </div>

                                <div className="lg:col-span-3 md:col-span-3">
                                    <h5 className="tracking-[1px] text-gray-100 font-bold">Company</h5>
                                    <ul className="list-none footer-list mt-5">
                                        <li className="ms-0">
                                            <Link to="/aboutus"
                                                className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center">
                                                <MdKeyboardArrowRight className="text-xl me-1" />About us
                                            </Link>
                                        </li>
                                        <li className="mt-[10px] ms-0">
                                            <Link to="/contact"
                                                className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center">
                                                <MdKeyboardArrowRight className="text-xl me-1" />Contact us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="lg:col-span-3 md:col-span-3">
                                    <h5 className="tracking-[1px] text-gray-100 font-bold">Usefull Links</h5>
                                    <ul className="list-none footer-list mt-5">
                                        <li className="ms-0">
                                            <Link to="https://storage.googleapis.com/thewide-static/privacy-policy.html" target="_blank" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center">
                                                <MdKeyboardArrowRight className="text-xl me-1" />Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="mt-[10px] ms-0">
                                            <Link to="/helpcenter"
                                                className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center">
                                                <MdKeyboardArrowRight className="text-xl me-1" />Help center
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid md:grid-cols-2 items-center">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-gray-400" style={{ fontSize: 13 }}>TheWide © {new Date().getFullYear()} - v{process.env.REACT_APP_VERSION} - alpha</p>
                        </div>

                        <ul className="list-none md:text-end text-center space-x-1 mt-6 md:mt-0">
                            <li className="inline text-gray-400">Built with <i className="mdi mdi-heart text-red-600"></i> in Paris, France.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

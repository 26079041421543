import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http/HttpLink';

export const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
    credentials: 'include',
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError && networkError.message?.includes('401')) {
        localStorage.removeItem('_1')
        window.location.href = '/login'
    };
});
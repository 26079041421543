import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import * as Icon from 'react-feather';
import { FaArrowRight } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import GetInTuch from '../../component/getInTuch';

export const helpData = [
    {
        icon:FaRegQuestionCircle,
        title:"FAQs",
        desc:"Explore our FAQ section for quick solutions.",
        link:"/faq"
    },
    // {
    //     icon:MdOutlineCollectionsBookmark,
    //     title:"Guides / Support",
    //     desc:"Our comprehensive guides and support resources are here to assist you.",
    //     link:"/helpcenter-guides",
    // },
    {
        icon:HiOutlineCog6Tooth,
        title:"Support Request",
        desc:"Submit a support ticket and our team will assist you.",
        link:"/contact",
    },
]

export default function Helpcenter() {

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Hello !</h3>
                        <p className='text-slate-300 mt-2 text-2xl' style={{ maxWidth: 420, margin: "0 auto" }}>How can we help you?</p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3 mb-2">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Help center</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Find The Help You Need</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Explore our knowledge base, FAQs, and contact support for assistance.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 mt-6 gap-[30px]">
                        {helpData.map((item, index) => {
                            let Ions = item.icon
                            return (
                                <div key={index} className="text-center px-6 mt-6">
                                    <div className="size-20 bg-blue-500/10 text-blue-500 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                        <Ions className="size-7" />
                                    </div>

                                    <div className="content mt-7">
                                        <Link to={item.link} className="title h5 text-lg font-medium hover:text-blue-500">{item.title}</Link>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>

                                        <div className="mt-5">
                                            <Link to={item.link} className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-blue-500 hover:text-blue-500 after:bg-blue-600 duration-500">Read More <FaArrowRight className="ms-2 text-[10px]" /></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Get Started</h3>
                        <p className="text-slate-400 max-w-xl mx-auto text-xl">Unlock your potential. Start your TheWide journey now.</p>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <div className="flex">
                            <Icon.HelpCircle className="fea icon-ex-md text-blue-500 me-3"></Icon.HelpCircle>
                            <div className="flex-1">
                                <h5 className="mb-2 text-xl font-semibold">Is TheWide <span className="text-blue-500">free</span> to use ?</h5>
                                <p className="text-slate-400"><b className="text-slate-300">Yes</b>, TheWide is completely free to use. No credit card information is required to sign up or use the platform. You can even earn rewards in the form of Sparks, which can be redeemed for real-world value.</p>
                            </div>
                        </div>

                        <div className="flex">
                            <Icon.HelpCircle className="fea icon-ex-md text-blue-500 me-3"></Icon.HelpCircle>
                            <div className="flex-1">
                                <h5 className="mb-2 text-xl font-semibold">Is TheWide only for coders?</h5>
                                <p className="text-slate-400"><b className="text-slate-300">No</b>, TheWide is a community for everyone interested in technology, regardless of their coding experience. Whether you're a seasoned developer, a curious learner, or simply passionate about tech, you'll find a welcoming space on TheWide.</p>
                            </div>
                        </div>

                        <div className="flex">
                            <Icon.HelpCircle className="fea icon-ex-md text-blue-500 me-3"></Icon.HelpCircle>
                            <div className="flex-1">
                                <h5 className="mb-2 text-xl font-semibold">What are <span className="text-blue-500">Sparks</span>?</h5>
                                <p className="text-slate-400">Sparks are our in-app currency that you earn by actively participating in the community. You can earn Sparks by posting, commenting, and engaging with other users.</p>
                            </div>
                        </div>

                        <div className="flex">
                            <Icon.HelpCircle className="fea icon-ex-md text-blue-500 me-3"></Icon.HelpCircle>
                            <div className="flex-1">
                                <h5 className="mb-2 text-xl font-semibold">How can I redeem my <span className="text-blue-500">Sparks</span>?</h5>
                                <p className="text-slate-400">Once you've accumulated enough Sparks, you can redeem them for real-world rewards. We'll provide detailed instructions on how to redeem your Sparks.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <GetInTuch />
                
            </section>
            <Footer />
        </>
    )
}

import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Link as Link2 } from 'react-scroll';

import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';

import { MdKeyboardArrowRight } from 'react-icons/md';
import GetInTuch from '../../component/getInTuch';

export default function HelpcenterFaqs() {

    const initialState = {
        activeAbout: -1,
        activeAccount: -1,
        activeRewards: -1,
        activeShorts: -1
    };

    const toggleAccordion = (category, index) => {
        dispatch({ type: 'SELECT_CATEGORY', payload: { category: category, index: index } });
    };

    const reducerMethod = (state, action) => {
        switch (action.payload.category) {
            case 'about':
                return {
                    ...state,
                    activeAbout: state.activeAbout === action.payload.index ? -1 : action.payload.index
                };
            case 'account':
                return {
                    ...state,
                    activeAccount: state.activeAccount === action.payload.index ? -1 : action.payload.index
                };
            case 'rewards':
                return {
                    ...state,
                    activeRewards: state.activeRewards === action.payload.index ? -1 : action.payload.index
                };
            case 'shorts':
                return {
                    ...state,
                    activeShorts: state.activeShorts === action.payload.index ? -1 : action.payload.index
                };
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducerMethod, initialState);

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Frequently Asked Questions</h3>
                        <p className='text-slate-300 mt-2 text-xl' style={{ maxWidth: 420, margin: "0 auto" }}>Explore our FAQ section for quick solutions.</p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/helpcenter">Helpcenter</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">FAQ</li>
                    </ul>
                </div>
            </section>


            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-5">
                            <div className="rounded-md shadow dark:shadow-gray-800 p-6 sticky top-20">
                                <ul className=" list-none sidebar-nav mb-0 py-0" id="navmenu-nav">
                                    <li className="navbar-item p-0"><Link2 to="about" spy={true}  activeClass="active" offset={-100} smooth={false} className="text-base font-medium navbar-link">About TheWide</Link2></li>
                                    <li className="navbar-item mt-3 p-0"><Link2 to="account" spy={true} activeClass="active" offset={-100} smooth={false} className="text-base font-medium navbar-link">Account</Link2></li>
                                    <li className="navbar-item mt-3 p-0"><Link2 to="rewards" spy={true} activeClass="active" offset={-100} smooth={false} className="text-base font-medium navbar-link">Reward system and monetization</Link2></li>
                                    <li className="navbar-item mt-3 p-0"><Link2 to="shorts" spy={true} activeClass="active" offset={-100} smooth={false} className="text-base font-medium navbar-link">Shorts</Link2></li>
                                </ul>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-7">

                            {/* about section */}
                            <div id="about">
                                <h5 className="text-2xl font-semibold">About TheWide</h5>

                                <div id="accordion-collapseone" data-accordion="collapse" className="mt-6">

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('about', 0)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAbout === 0 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>The Concept</span>
                                                <svg data-accordion-icon className={`${state.activeAbout === 0 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAbout === 0 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        TheWide is a new social network tailored specifically for developers, programmers, and coders, offering a unique concept.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        On TheWide, users can join a community of developers or IT specialists based on their preferred technologies, in order to benefit from a highly targeted and collaborative community. In addition to classic features such as image and video sharing, chat, reactions, and comments, TheWide offers special and unique features, including:
                                                    </p>
                                                    <ul className="list-none footer-list mt-5 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Direct Source Code Sharing in the Feed: Users can share source code by tapping the "+" button located at the bottom of the screen.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Code Discussions:  To facilitate code reviews and collaboration among developers, it is possible to engage in discussions using code.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Reward system: TheWide has a reward system allowing users to monetize their activity on the platform.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Idea Center: Users can propose features they deem useful for the application via an idea center accessible quickly from the menu bar.
                                                        </li>
                                                        <li className="flex ml-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Bug Reporting System: Users can quickly report encountered issues, allowing the development team to intervene promptly through the idea center.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('about', 1)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAbout === 1 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Updates</span>
                                                <svg data-accordion-icon className={`${state.activeAbout === 1 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAbout === 1 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        The platform is in active development; we update the web application as well as the mobile applications weekly with fixes, and we perform major updates every month.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        The most innovative features of TheWide project have not yet been deployed. We need to ensure that the basic features are available and stable before launching the most groundbreaking features ever seen on a social network.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('about', 2)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAbout === 2 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>The company</span>
                                                <svg data-accordion-icon className={`${state.activeAbout === 2 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAbout === 2 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        A startup located in Paris, France.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            {/* account section */}
                            <div id="account" className="mt-8">
                                <h5 className="text-2xl font-semibold">Account</h5>

                                <div id="accordion-collapsetwo" data-accordion="collapse" className="mt-6">

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('account', 0)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAccount === 0 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Account Verification</span>
                                                <svg data-accordion-icon className={`${state.activeAccount === 0 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAccount === 0 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        In order to prevent fake profiles, confusion, or issues associated with fake accounts, TheWide has implemented profile verification using a phone number and an email address.
                                                        This ensures the reliability of accounts and enhances platform security.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        Your phone number and all other personal information will never be disclosed/sold to external entities of TheWide platform.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        Account verification is completely free of charge.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('account', 1)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAccount === 1 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Unverified Accounts</span>
                                                <svg data-accordion-icon className={`${state.activeAccount === 1 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAccount === 1 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Unverified profiles can only access the platform in read-only mode.
                                                        This means they can view content but cannot actively participate by posting content or interacting with other users until their profile has been verified.
                                                        However, unverified profiles will still be able to report bugs in the idea center.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('account', 2)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAccount === 2 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Account Deletion</span>
                                                <svg data-accordion-icon className={`${state.activeAccount === 2 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAccount === 2 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        You can delete your account at any time without any request necessary. Profile deletion is done from the settings page, then at the bottom of the screen, select "delete my account."
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        This action results in the permanent deletion of the account as well as all associated data (posts, comments, ideas, etc.).
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        This action is irreversible.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('account', 3)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeAccount === 3 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Security and Privacy</span>
                                                <svg data-accordion-icon className={`${state.activeAccount === 3 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeAccount === 3 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        User data will never be disclosed or sold to external sites. At TheWide, we prioritize the confidentiality and security of our users' data.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        Your personal information will remain strictly confidential and will only be used within the scope of the user experience on our platform.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* rewards/sparks system */}
                            <div id="rewards" className="mt-8">
                                <h5 className="text-2xl font-semibold">Reward system and monetization</h5>

                                <div id="accordion-collapsethree" data-accordion="collapse" className="mt-6">
                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 0)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 0 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Presentation</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 0 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 0 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        TheWide has a reward system allowing users to monetize their activity on the platform.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        Interactions on the platform accrue points (sparks) for users, which are automatically calculated and updated by the system every 15 minutes.
                                                    </p>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-2">
                                                        The interactions covered include:
                                                    </p>
                                                    <ul className="list-none footer-list mt-5 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Reactions:  (likes, dislikes, etc.) on posts, comments, and sub-comments.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Posts: (of all types) that have led to a minimum level of constructive collaboration with the community.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Shorts: shorts that have reached the minimum threshold of views.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Reported bugs: the bugs that are tagged as confirmed by the administrators.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Proposed ideas:the ideas that are tagged as deployed by the administrators.
                                                        </li>
                                                    </ul>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-4">
                                                        Some actions will not be taken into account by the algorithm:
                                                    </p>
                                                    <ul className="list-none footer-list mt-3 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Irrelevant comments.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Posts containing external links.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Unvalidated bugs.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Undeveloped ideas.
                                                        </li>
                                                    </ul>
                                                    <p className="text-slate-400 dark:text-gray-400 mt-4">Notes:</p>

                                                    <ul className="list-none footer-list mt-3 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Publishing a post does not guarantee points. The post must contribute to meaningful discussions and collaborations within the community.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            The number of points awarded varies depending on the type and quality of the interaction.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            The points calculation formula is confidential and may evolve with the platform's development.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Users cannot have a negative balance of points.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 1)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 1 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Objective</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 1 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 1 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        The goal of the sparks system is to encourage users to create higher quality content. The more interactions the published content generates, the more sparks the user earns.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 2)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 2 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Sparks value</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 2 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 2 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        The value of Sparks is subject to change over time, and may increase or decrease.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 3)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 3 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>When can i convert my sparks to cash?</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 3 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 3 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Sparks-to-cash transfers can be made on demand.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 4)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 4 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Minimum sparks requirement for transfer</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 4 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 4 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Sparks can be converted to dollars upon request exclusively through the 'Sparks Dashboard' once the user has reached the minimum Spark threshold.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 5)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 5 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>How do i retrieve my sparks balance?</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 5 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 5 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Sparks transfers are made via PayPal within 72 business hours.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 6)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 6 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Conditions for point retrieval</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 6 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 6 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Users meeting the minimum sparks requirement must fulfill these conditions to retrieve their balance in cash:
                                                    </p>
                                                    <ul className="list-none footer-list mt-3 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Have a PayPal account.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Have their real first name and last name indicated on the platform.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Have a valid card ID with the same name used on the platform.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Have verified their account on TheWide.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 7)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 7 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Transfer fees</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 7 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 7 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        TheWide does not charge any fees on sparks earned and transferred by its users. Transfer fees may be charged by the PayPal platform, which vary depending on the destination country; these fees are entirely borne by the user receiving the money.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 8)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 8 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Sparks limit</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 8 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 8 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        The platform has no limits/cap on sparks to be earned.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 9)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 9 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Sparks calculation system update</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 9 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 9 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        TheWide is currently in alpha version; our point calculation algorithm may change through updates and may affect users' existing sparks, either positively or negatively.
                                                        For this purpose, we have applied a x2 bonus during the alpha phase.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 10)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 10 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Penalties and sparks loss</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 10 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 10 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Users may see their sparks decrease in the following cases:
                                                    </p>
                                                    <ul className="list-none footer-list mt-3 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Posts, comments, or replies with a high level of negative interactions will be ignored and will not earn their author any sparks.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Abusive use of the platform may result in partial or total loss of sparks.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Users may experience a total decrease in sparks if the point calculation algorithm updates with new restrictions.
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('rewards', 11)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeRewards === 11 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Abusive use of the platform</span>
                                                <svg data-accordion-icon className={`${state.activeRewards === 11 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeRewards === 11 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        If we detect abnormal usage of the platform, we will conduct an advanced investigation. If a user abuses or cheats using the platform, they will experience partial or total loss of their points.
                                                        Unacceptable actions include:
                                                    </p>
                                                    <ul className="list-none footer-list mt-3 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Using fake profiles to create fake interactions.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Creating content without value to maximize points.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Any other actions deemed non-compliant by our administrators.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            {/* shorts */}
                            <div id="shorts" className="mt-8">
                                <h5 className="text-2xl font-semibold">Shorts</h5>

                                <div id="accordion-collapsefour" data-accordion="collapse" className="mt-6">

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('shorts', 0)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeShorts === 0 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>What are Video Shorts?</span>
                                                <svg data-accordion-icon className={`${state.activeShorts === 0 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeShorts === 0 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        Video Shorts are short, vertical videos that are up to 60 seconds long. They are designed to be concise, engaging, and easy to consume. Video Shorts can be used to:
                                                    </p>

                                                    <ul className="list-none footer-list mt-5 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Share tips and tricks.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Demonstrate coding techniques.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Showcase project progress.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Create tutorials.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            And much more!
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('shorts', 4)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeShorts === 4 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Why use Video Shorts?</span>
                                                <svg data-accordion-icon className={`${state.activeShorts === 4 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeShorts === 4 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        There are many benefits to using Video Shorts on TheWide:
                                                    </p>

                                                    <ul className="list-none footer-list mt-5 text-gray-400">
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Increased engagement: Video Shorts are more likely to be watched and engaged with than other types of content.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Improved reach: Video Shorts can be seen by a wider audience of developers on TheWide.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Establish yourself as an expert: Create informative and helpful Video Shorts to position yourself as a thought leader in your field.
                                                        </li>
                                                        <li className="flex ml-2 mb-2">
                                                            <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                            Grow your following: Attract new followers and connect with other developers.
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('shorts', 1)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeShorts === 1 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Earning Sparks with Video Shorts</span>
                                                <svg data-accordion-icon className={`${state.activeShorts === 1 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeShorts === 1 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        In addition to the other ways to earn sparks on TheWide, you can now also earn sparks by creating and sharing Video Shorts. Here's how it works:
                                                    </p>

                                                        <ul className="list-none footer-list mt-5 text-gray-400">
                                                            <li className="flex ml-2 mb-2">
                                                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                                You will earn sparks for every 10 views your Video Short receives.
                                                            </li>
                                                            <li className="flex ml-2 mb-2">
                                                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                                If you are the original author of the video and you check the "I am the author of this short" box during creation, you will receive a bonus sparks.
                                                            </li>
                                                            <li className="flex ml-2 mb-2">
                                                                <MdKeyboardArrowRight className="text-xl mr-2 mt-1" />
                                                                If you check the "I am the author of this short" box and you are not the original author of the video, you will receive a penalty and may lose all of your sparks.
                                                            </li>
                                                        </ul>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('shorts', 2)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeShorts === 2 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Bonus points for original authors</span>
                                                <svg data-accordion-icon className={`${state.activeShorts === 2 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeShorts === 2 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        We want to encourage users to create original content, so we are offering a bonus point to users who are the original authors of their Video Shorts. To receive the bonus point, simply check the "I am the author of this short" box during creation.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion('shorts', 3)} type="button"
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeShorts === 3 ? 'bg-gray-50 dark:bg-slate-800 text-blue-500' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>Penalty for false claims of authorship</span>
                                                <svg data-accordion-icon className={`${state.activeShorts === 3 ? "rotate-180" : "rotate-270"} size-4 shrink-01`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {state.activeShorts === 3 && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">
                                                        We take plagiarism very seriously. If you check the "I am the author of this short" box and you are not the original author of the video, you will receive a penalty. The penalty may include the loss of all of your points.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <GetInTuch />
            </section>

            <Footer />

        </>
    )
}

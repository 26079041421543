import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import logo_light from '../../assets/images/logo-white.png';
import { useMutation } from '@apollo/client';
import { LOGIN_USER_MUTATION } from '../../graphql/mutations/auth';
import { isEmailValid, isPasswordValid } from '../../utils/Validator';
import { useAuth } from '../../utils/useAuth';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

export default function AuthLogin() {

    const navigate = useNavigate();
    const { loggedIn, } = useAuth();

    const [showPwd, setShowPwd] = useState(false)
    const [rememberMe, setRememberMe] = React.useState(localStorage.getItem("rememberMe") === "true")
    const [loginError, setLoginError] = useState('');
    const [badEmailText, setBadEmailText] = useState();
    const [user, setUser] = useState({
        email: localStorage.getItem("email") ?? "",
        passwordHash: process.env.REACT_APP_DEFAULT_LOGIN_PASSWORD
    })
    const [login, loginQuery] = useMutation(LOGIN_USER_MUTATION, { fetchPolicy: "network-only" });

    const handleLoginClick = async (e) => {
        e.preventDefault()
        login({
            variables: {
                email: user.email,
                password: user.passwordHash,
            }
        }).then((res) => {
            const OldRememberMe = localStorage.getItem("rememberMe")
            if (rememberMe) {
                localStorage.setItem("rememberMe", "true")
                localStorage.setItem("email", user.email + "")
            }
            else if (!rememberMe && OldRememberMe === "true") {
                localStorage.setItem("rememberMe", "false")
                localStorage.setItem("email", "")
            }
        })
    }

    const handleInputChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value })
    }

    const validForm = () => {
        if (user.email && user.passwordHash) {
            return isEmailValid(user.email) && isPasswordValid(user.passwordHash)
        }
        return false
    }

    const handleChange = () => {
        setRememberMe(!rememberMe)
    }

    const handleEmailBlur = () => {
        const _email = user.email?.trim()
        setUser({ ...user, email: _email })

        if (Number(_email?.length) > 0 && !isEmailValid(_email ?? '')) {
            setBadEmailText('Bad email.')
        }
        else {
            setBadEmailText(undefined)
        }
    }

    useEffect(() => {
        //login errors
        if (loginQuery?.error) {
            setLoginError(loginQuery?.error.message)
            console.error(JSON.stringify(loginQuery?.error))
        }
        //login ok
        if (loginQuery?.data) {
            window.location.href = process.env.REACT_APP_ENDPOINT
        }
    }, [loginQuery?.data, loginQuery?.error, navigate])

    useEffect(() => {
        if (loggedIn()) {
            window.location.href = process.env.REACT_APP_ENDPOINT
        }
    }, [loggedIn])

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Sign in to your account</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Log in</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl py-6 px-12" style={{ maxWidth: 480, margin: "0 auto" }}>
                                    <div>
                                        <img src={logo_light} style={{ width: 120, margin: "0 auto", marginBottom: 34 }} alt="" />
                                    </div>

                                    <h3 className="mb-3 text-2xl leading-normal font-medium text-blue-500">Welcome Back!</h3>

                                    <p className='text-slate-300 mb-10'>Log in to connect, learn, and grow.</p>

                                    <form className="text-start">
                                        <div className="grid grid-cols-1">
                                            <div>
                                                <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                                <input
                                                    id="LoginEmail"
                                                    type="email"
                                                    name="email"
                                                    value={user.email}
                                                    onChange={handleInputChange}
                                                    onBlur={handleEmailBlur}
                                                    className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-blue-500 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                    placeholder="name@example.com" />
                                                <div className='error'>
                                                    <span className='error_message'>{badEmailText}</span>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        id="LoginPassword"
                                                        name="passwordHash"
                                                        type={showPwd ? 'text' : 'password'}
                                                        onChange={handleInputChange}
                                                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-black-0 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-blue-500 dark:border-gray-800 dark:focus:border-blue-500 focus:ring-0"
                                                        placeholder="Password:" />
                                                    <div className='showPwd' onClick={() => setShowPwd(!showPwd)}>
                                                        {
                                                            !showPwd ? <FaRegEye /> : <FaRegEyeSlash />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='error'>
                                                    <span className='error_message'>
                                                        {
                                                            user?.passwordHash?.length > 0 && user?.passwordHash?.length < 6 ?
                                                                "At least 6 characters."
                                                                : loginError
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex justify-between mb-4">
                                                <div className="flex items-center mb-0">
                                                    <input
                                                        id="RememberMe"
                                                        onChange={handleChange}
                                                        name="rememberMe"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2"
                                                        type="checkbox"
                                                        value={rememberMe}
                                                    />
                                                    <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                                </div>
                                                <p className="text-slate-400 mb-0"><Link to="/password-reset" className="text-slate-400">Forgot password ?</Link></p>
                                            </div>

                                            {/* Login btn */}
                                            <div className="mb-4">
                                                <input
                                                    type="submit"
                                                    onClick={handleLoginClick}
                                                    disabled={loginQuery?.loading || !validForm()}
                                                    className="mt-4 py-2 hover px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 text-white rounded-2xl w-full"
                                                    value="Log in" />
                                            </div>

                                            <div className="text-center">
                                                <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/signup" className="text-black dark:text-white font-bold inline-block">Sign Up</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

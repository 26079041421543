import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import Navbar from '../../component/Navbar/navbar';
import * as Icon from 'react-feather';
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function Contact() {

    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-20 bg-black bg-center bg-no-repeat bg-cover bg-[url('../../assets/images/bg3.png')]">
                <div className="absolute inset-0"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl md:leading-normal tracking-wide leading-normal font-medium text-white">Contact Us</h3>
                        <p className='text-slate-300 mt-2 text-xl' style={{ maxWidth: 420, margin: "0 auto" }}>Got a question, suggestion, or bug report? We're here to help.</p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3 mb-2">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Home</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl" /></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Contact Us</li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-12 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">

                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-secondary rounded-2xl p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                    <p className='text-slate-300 mb-8'>We're here to help with any questions, feedback, or technical issues you may encounter.</p>

                                    <form>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                        <input disabled name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-black-0 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                        <input disabled name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-black-0 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.Book className="size-4 absolute top-3 start-4"></ Icon.Book>
                                                        <input disabled name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-black-0 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                        <textarea disabled name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-black-0 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button type="submit" id="submit" name="send" disabled
                                                className="py-2 px-8 font-semibold tracking-wide align-middle duration-500 text-base text-center bg-blue-500 hover:bg-blue-400 text-white rounded-2xl">
                                                Submit
                                            </button>
                                        </div>
                                        <p className="mb-0 mt-2 text-gray-400" style={{ fontSize: 14 }}>Email: contact@thewide.com</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
